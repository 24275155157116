<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import {get, post} from '@/api/httpHelp'

export default {
  name: 'App',
  data() {
    return {
      url: {
        getAccessToken: '/wx/getAccessToken',
        listSignUp: '/listSignUp'
      }
    }
  },
  watch: {},
  created() {
    // 判断当前路由地址
    console.log("APP created", this.$route)
  },
  // async created() {
  //   let openId = window.localStorage.getItem('openId');
  //   if(openId && openId !== ''){
  //     await this.getMatchConfig();
  //     await this.checkHasData(openId).then(data => {
  //       if (data && data.length > 0) {
  //         this.$router.push({
  //           path: '/signUpList'
  //         })
  //       } else {
  //         this.$router.push({
  //           path: '/home'
  //         })
  //       }
  //     })
  //     return
  //   }
  //   // 判断结束
  //   let code = this.getParameter('code')
  //   if (code && code !== '') {
  //     await this.getOpenId(code).then(async res => {
  //       if (res && res !== '') {
  //         await this.getMatchConfig();
  //         await this.checkHasData(res).then(data => {
  //           if (data && data.length > 0) {
  //             this.$router.push({
  //               path: '/signUpList'
  //             })
  //           } else {
  //             this.$router.push({
  //               path: '/home'
  //             })
  //           }
  //         })
  //       }
  //     })
  //   } else {
  //     let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbc2307c92d5395b7&redirect_uri=' + encodeURIComponent("https://wxbm.121xuexi.com") + '&response_type=code&scope=snsapi_base&state=dxtx&connect_redirect=1#wechat_redirect'
  //     //window.location.replace(url);
  //     window.location.href = url;
  //   }
  // },
  methods: {
    ...mapActions('match', ['getMatchConfig']),
    getParameter: function (str) {
      let url = window.location.href.split("?")[1]
      if (!url) {
        return null
      }
      url = url.substring(0, url.length - 2)
      let arr = url.split("&")
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let itemArr = arr[i].split("=")
          if (itemArr && itemArr.length > 0) {
            if (str === itemArr[0]) {
              return itemArr[1]
            }
          }
        }
      }
      return null
    },
    async checkHasData(openId) {
      let d
      await get(this.url.listSignUp, {
        openId: openId
      }).then(res => {
        if (res != null) {
          d = res.result
        }
      }).catch(() => {
      }).finally(() => {
      })
      return d
    },
    async getOpenId(code) {
      let d = ''
      await post(this.url.getAccessToken, {
        code: code
      }).then(res => {
        if (res != null && res.result.openid) {
          window.localStorage.setItem('openId', res.result.openid)
          d = res.result.openid
        }
      }).catch(() => {
      }).finally(() => {
      })
      return d
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  font-size: 14px;
  background-color: #f2f2f5;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app_page_container {
  min-height: 100vh;
  box-sizing: border-box;
  /*height: 100vh;*/
}

.van-cell {
  padding: 14px !important;
  box-sizing: border-box;
}

.title_1 {
  font-size: 1.1em;
  color: #333;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  padding: 0 14px;
}

.van-nav-bar {
  z-index: 999;
}

.van-radio {
  padding: 5px !important;
}

</style>
