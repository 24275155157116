import Vue from 'vue'
import Vuex from 'vuex'
import match from './match/index'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        match
    }
})
