import axios from "axios";
import {Toast} from 'vant'

export const baseUrl = 'https://wxbm.121xuexi.com/match_support';
// export const baseUrl = 'http://192.168.3.69:9099/match_support'
axios.defaults.baseURL = baseUrl

const httpHelp = (res) => {
    if (res.data) {
        if (res.data.success) {
            return res.data
        } else {
            Toast.fail(res.data.message)
            return null
        }
    } else {
        Toast.fail("获取数据失败！")
        return null
    }
};

const catchError = (error) => {
    Toast.fail('服务出错')
    return Promise.reject(new Error(error))
};
// get
export const get = (url, params) => {
    return axios.get(`${url}`, {params})
        .then((res) => {
            return httpHelp(res)
        })
        .catch((err) => {
            return catchError(err)
        });
};

// post
export const post = (url, data) => {
    return axios.post(`${url}`, data)
        .then((res) => {
            return httpHelp(res)
        }).catch((err) => {
            return catchError(err)
        });
};


/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
    return axios({
        url: url,
        params: parameter,
        method: 'get',
        responseType: 'blob'
    })
}
