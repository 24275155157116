import {get,post} from './httpHelp'

export const getDetailMatchConfig=()=>{
    return get(`detailMatchConfig`)
}
export const uploadImg=(params)=>{
    return post(`/upload/image`,params)
}

export const addSignUp = (url, params) => {
    return post(`${url}`, params)
}
