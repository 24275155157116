import {getDetailMatchConfig} from "@/api/requestApi";

const state = {
    matchConfig: {},
    activeCategoryItem: {},
    categoryItemVoList: []
};
const getters = {
    matchConfig: state => state.matchConfig,
    activeCategoryItem: state => state.activeCategoryItem,
    categoryItemVoList: state => state.categoryItemVoList
};
const mutations = {
    SET_MATCH_CONFIG: (state, matchConfig) => {
        state.matchConfig = matchConfig
    },
    SET_ACTIVE_CATEGORY_ITEM: (state, categoryItem) => {
        state.activeCategoryItem = categoryItem
    },
    SET_CATEGORY_ITEM_VO_LIST: (state, categoryItemVoList) => {
        state.categoryItemVoList = categoryItemVoList
    }
};
const actions = {
    async getMatchConfig({commit}) {
        await getDetailMatchConfig().then(res => {
            if (res == null){
                return
            }
            commit('SET_MATCH_CONFIG', res.result || {});
            commit('SET_CATEGORY_ITEM_VO_LIST', res.result.categoryItemVoList || []);
        })
    }
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
