import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/wx'
    },
    {
        path: '/wx',
        name: 'wxLayout',
        component: () => import('@/components/layouts/WxLayout'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/Home')
            },
            {
                path: 'teamType',
                name: 'teamType',
                component: () => import('@/views/term/TeamType')
            },
            {
                path: 'form',
                name: 'form',
                component: () => import('@/views/form/Form')
            },
            {
                path: 'submitOk',
                name: 'submitOk',
                component: () => import('@/views/form/SubmitOk')
            },
            {
                path: 'signUpList',
                name: 'signUpList',
                component: () => import('@/views/sign_up/SignUpList')
            }
        ]
    },
    {
        path: '/system/7txKz8qOSdrPNsUlLmYBx5vZJCMfkaIj',
        name: 'systemLayout',
        redirect: '/system/7txKz8qOSdrPNsUlLmYBx5vZJCMfkaIj/unitList',
        component: () => import('@/components/layouts/SystemLayout'),
        children: [
            {
                path: 'unitList',
                name: 'unitList',
                component: () => import('@/views/system/unitList/UnitList')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path.startsWith("/wx")) {
        if (Object.keys(store.state.match.matchConfig).length === 0) {
            store.dispatch('match/getMatchConfig').then(() => {
                next()
            })
        } else {
            next()
        }
    } else if (to.path.startsWith("/system")) {
        next()
    } else {
        next()
    }
})

// router.beforeEach((to, from, next) => {
//     if (to.path.startsWith('/system')) {
//         next()
//         return
//     }
//     if (to.path !== '/home' && from.name) {
//         if (typeof store.state.match.matchConfig == "undefined" || Object.keys(store.state.match.matchConfig).length <= 0) {
//             store.dispatch('match/getMatchConfig').then(() => {
//             })
//             next({path: '/home'})
//         } else {
//             next()
//         }
//     } else if (to.path === '/form' && store.state.match.activeCategoryItem.length === 0) {
//         next({path: '/home'})
//     } else {
//         next()
//     }
// })

export default router
